import React, { useState } from "react";
import logo from "../assets/logo.png";
//import Print from "./Print";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Draggable from 'react-draggable';
// Importa las fuentes
//import font1 from '../assets/fonts/Dancing_Script/static/DancingScript-SemiBold.ttf';
import "../assets/fonts/Dancing_Script/static/DancingScript-Medium-normal.js"
import "../assets/fonts/Grandest-Serif-normal.js"
import "../assets/fonts/PlayfairDisplay-Medium-normal.js"
//carta
import "../assets/fonts/Whisper-Regular-normal.js"
import "../assets/fonts/Pacifico-Regular-normal.js"
import "../assets/fonts/CedarvilleCursive-Regular-normal.js"


//molde
import "../assets/fonts/Quicksand-Medium-normal.js"
import "../assets/fonts/BebasNeue-Regular-normal.js"
import "../assets/fonts/BebasNeue-Regular-normal.js"
import "../assets/fonts/Noto_Color_Emoji/NotoColorEmoji-Regular.ttf"
//image
import botella from '../assets/label/globo.png'

import "./style.css"

/* import font2 from '../assets/font2.ttf';
import font3 from '../assets/font3.ttf';
import font4 from '../assets/font3.ttf'; */

const App = () => {



    const [image, setImage] = useState();
    const [text, setText] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');

    const [textFlag, setTextFlag] = useState(false);
    const [imageFlag, setimageFlag] = useState(false);


    const [selectedColor, setSelectedColor] = useState('#ff0000'); // Color inicial
    const [selectedColorImage, setSelectedColorImage] = useState('#f0000'); // Color inicial
    const [deltaPosition, setdeltaPosition] = useState({ x: 0, y: 0 });
    const [activeDrags, setactiveDrags] = useState(0);
    const [coordenadas, setcoordenadas] = useState('0');
    const [size, setSize] = useState('0');
    const [font, setFont] = useState('0');
    const [center, setCenter] = useState('0');


    const handleDrag = (e, ui) => {
        const { x, y } = deltaPosition;
        setdeltaPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        })
        /*  this.setState({
             deltaPosition: {
                 x: x + ui.deltaX,
                 y: y + ui.deltaY,
             }
         }); */
    };

    const onStart = () => {
        let number = activeDrags
        setactiveDrags(number + 1)
        // this.setState({ activeDrags: ++this.state.activeDrags });
    };

    const onStop = () => {
        let number = activeDrags
        setactiveDrags(--number)
        //this.setState({ activeDrags: --this.state.activeDrags });
    };


    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleTextChange2 = (event) => {
        setText2(event.target.value);
    };

    const handleTextChange3 = (event) => {
        setText3(event.target.value);
    };

    const handleColorChange = (event) => {
        setSelectedColor(event.target.value);
    };

    const handleColorChangeImage = (event) => {
        setSelectedColorImage(event.target.value);
    };

    function hexToRgb(hex) {
        // Elimina el símbolo '#' si está presente
        hex = hex.replace(/^#/, '');

        // Divide el valor hexadecimal en componentes R, G y B
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Devuelve el valor RGB en formato "rgb(r, g, b)"
        return { r, g, b };
    }

    function createPDF() {
        


        // Crea un nuevo documento PDF
        const doc = new jsPDF({
            unit: 'in', // Configura la unidad como pulgadas
            format: 'letter',
        });
        doc.setFont("NotoColorEmoji");
        // Agrega una imagen al documento
        //const imgData = URL.createObjectURL(image); // Cambia la ruta a la ubicación de tu imagen
        //doc.addImage(imgData, image.type, 48, 47, 112, 38); // Cambia las coordenadas y el tamaño de la imagen

        // Coordenadas y dimensiones en píxeles
        const xPixels = 46;
        const yPixels = 47.9;
        const widthPixels = 112;
        const heightPixels = 38;

        // Conversión a pulgadas (considerando la resolución de 72 píxeles por pulgada)
        const xInches = xPixels / 72;  // Conversión de píxeles a pulgadas
        const yInches = yPixels / 72;
        const widthInches = widthPixels / 72;
        const heightInches = heightPixels / 72;

        if (imageFlag) {
            const imgData = URL.createObjectURL(image); // Cambia la ruta a la ubicación de tu imagen
            doc.addImage(imgData, image.type, 1.92, 1.815, 4.39, 1.5); // Cambia las coordenadas y el tamaño de la imagen
        } else {
            const color = hexToRgb(selectedColorImage)
            doc.setFillColor(color.r, color.g, color.b);
            doc.rect(1.92, 1.83, 4.39, 1.5, 'F')
        }

        if (textFlag) {
            //var x = doc.internal.pageSize.getWidth() - (doc.internal.pageSize.getWidth() / 2); // Ajusta el valor según el espacio desde el borde derecho
            //var y = 20;

            // Define el tamaño de fuente original
            var fontSize = 10;
            if (size === '0') {
                fontSize = 18
            } else if (size === '1') {
                fontSize = 20
            } else if (size === '2') {
                fontSize = 22
            } else if (size === '3') {
                fontSize = 24
            }


            // Cambia la fuente según la opción seleccionada
            let fontS;
            
            if (font === '0') {
                doc.setFont('DancingScript-Medium');
            } else if (font === '1') {
                doc.setFont('Ubuntu-Medium');





                //molde
            } else if (font === '2') {
                doc.setFont('PlayfairDisplay-Medium');
            } else if (font === '3') {
                doc.setFont('Quicksand-Medium');
            } else if (font === '4') {
                doc.setFont('BebasNeue-Regular');
            }
            //carta
            else if (font === '5') {
                doc.setFont('Whisper-Regular');
            }
            else if (font === '6') {
                doc.setFont('Pacifico-Regular');
            }
            else if (font === '7') {
                doc.setFont('BeauRivage-Regular');
            }
            else if (font === '8') {
                doc.setFont('CedarvilleCursive-Regular');
            }
            doc.setFont('NotoColorEmoji')


            // Load the plugin's font
            //doc.setFont(font1);

            // Cambia a la fuente cargada
            //doc.setFont('DancingScript');




            doc.setFontSize(fontSize);
            // Obtener el ancho del texto y del documento
            const anchoTexto = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
            const anchoDocumento = doc.internal.pageSize.width;

            const anchoTexto2 = doc.getStringUnitWidth(text2) * fontSize / doc.internal.scaleFactor;
            const anchoDocumento2 = doc.internal.pageSize.width;

            const anchoTexto3 = doc.getStringUnitWidth(text3) * fontSize / doc.internal.scaleFactor;
            const anchoDocumento3 = doc.internal.pageSize.width;


            // Cambia el color del trazo (color del texto) a rojo
            const color = hexToRgb(selectedColor)
            doc.setTextColor(color.r, color.g, color.b);
            let pdfX = 0
            let pdfY = 0
            console.log(text.length / 2)
            if (coordenadas === '1') {
                pdfX = ((anchoDocumento - anchoTexto) / 3.0)
                pdfY = (doc.internal.pageSize.height / 2) - 3;
            } else if (coordenadas === '2') {
                pdfX = ((anchoDocumento - anchoTexto) / 3.0)
                pdfY = (doc.internal.pageSize.height / 2) - 2.6;
            } else if (coordenadas === '3') {
                pdfX = (1.21 / window.innerWidth) * doc.internal.pageSize.getWidth() + 48
                pdfY = (129.26 / window.innerHeight) * (doc.internal.pageSize.getHeight()) + 47
            } else if (coordenadas === '4') {
                pdfX = ((anchoDocumento - anchoTexto) / 2.05)
                pdfY = (doc.internal.pageSize.height / 2) - 2.6;
            }





            else if (coordenadas === '0') {
                pdfX = ((anchoDocumento - anchoTexto) / 2.05)
                pdfY = (doc.internal.pageSize.height / 2) - 3;
            }

            else if (coordenadas === '7') {
                pdfX = ((anchoDocumento - anchoTexto) / 1.6)
                pdfY = (doc.internal.pageSize.height / 2) - 3;
            }
            else if (coordenadas === '8') {
                pdfX = ((anchoDocumento - anchoTexto) / 1.6)
                pdfY = (doc.internal.pageSize.height / 2) - 2.6;
            }
            
            else if (coordenadas === '5') {
                pdfX = ((anchoDocumento - anchoTexto) / 2.05)
                pdfY = (doc.internal.pageSize.height / 2) - 3.4;
            } else if (coordenadas === '6') {
                pdfX = ((anchoDocumento - anchoTexto) / 2.05)
                pdfY = (doc.internal.pageSize.height / 2) - 2.3;
            }
            // Agrega texto encima de la imagen
            doc.text(text, pdfX, pdfY); // Cambia el texto y las coordenadas

            if (text2 != '' || text2) {
                pdfX = ((anchoDocumento2 - anchoTexto2) / 2.05)
                doc.text(text2, pdfX, pdfY + (fontSize * 0.018)); // Cambia el texto y las coordenadas

            }

            if (text3 != '' || text3) {
                pdfX = ((anchoDocumento3 - anchoTexto3) / 2.05)
                doc.text(text3, pdfX, pdfY + (fontSize * 0.035)); // Cambia el texto y las coordenadas
            }

        }

        // Guarda el PDF o muéstralo en una ventana nueva
        doc.save('archivo.pdf'); // Puedes cambiar el nombre del archivo según tus necesidades
        //window.location.reload();
    }
    const dragHandlers = { onStart: onStart, onStop: onStop };
    return (

        <div className="fondo" style={{ paddingTop: '0vh', display: 'flex', flexDirection: 'column', height: 'auto' }}>
            <div style={{ width: '150px', placeSelf: 'center', paddingBottom: '0px' }}>
                <img style={{ width: '100%' }} src={logo} alt="johnie" />
            </div>
            {/* FONDO */}
            <label htmlFor="includeTextCheckboxImage" style={{ textAlign: 'center' }}>
                <input
                    style={{ marginTop: '0px' }}
                    type="checkbox"
                    id="includeTextCheckboxImage"
                    checked={imageFlag}
                    onChange={() => setimageFlag(!imageFlag)}
                />
                Incluir Imagen
            </label>
            {
                imageFlag ?
                    <input
                        style={{ textAlignLast: 'center' }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        placeholder="Agrega un Fondo"
                    />
                    :
                    <div style={{ textAlignLast: 'center' }}>

                        <input
                            style={{ marginTop: '10px', height: '50px', textAlignLast: 'center' }}
                            type="color"
                            id="colorPickerImage"
                            value={selectedColorImage}
                            onChange={handleColorChangeImage}
                        />
                    </div>
            }

            {/* TEXT */}
            <label htmlFor="includeTextCheckbox" style={{ textAlign: 'center' }}>
                <input
                    style={{ marginTop: '25px' }}
                    type="checkbox"
                    id="includeTextCheckbox"
                    checked={textFlag}
                    onChange={() => setTextFlag(!textFlag)}
                />
                Incluir texto
            </label>
            {
                textFlag &&
                <>
                    <div style={{ textAlign: 'center', }}>
                        <input
                            style={{ width: '60%', height: '40px', marginBottom: '10px' }}
                            type="textArea"
                            onChange={handleTextChange}
                            placeholder="Agrega un Texto"
                        />
                        <input
                            style={{ width: '60%', height: '40px', marginBottom: '10px' }}
                            type="textArea"
                            onChange={handleTextChange2}
                            placeholder="Agrega un Texto"
                        />
                        <input
                            style={{ width: '60%', height: '40px', marginBottom: '10px' }}
                            type="textArea"
                            onChange={handleTextChange3}
                            placeholder="Agrega un Texto"
                        />
                        <br></br>
                        <select
                            style={{ marginLeft: '30px', height: '30px', marginTop: '20px' }}
                            type="select"
                            name="orientacion"
                            id="orientacion"
                            placeholder="Orientación"
                            onChange={(e) => setcoordenadas(e.target.value)}
                            defaultValue={"0"}
                        >
                            <option value="5">Arriba</option>
                            <option value="6">Abajo</option>
                            <option value="4">Medio</option>
                            <option value="0">Centro</option>
                            
                            <option value="1">Lateral Superior Izquierdo</option>
                            <option value="2">Lateral Inferior Izquierdo</option>
                           
                            <option value="7">Lateral Superior Derecha</option>
                            <option value="8">Lateral Inferior Derecha</option>
                            {/* <option value="1">Superior Izquierda</option>
                            <option value="2">Superior Derecha</option>
                            <option value="3">Inferior Izquierda</option>
                            <option value="4">Inferior Derecha</option> */}
                        </select>
                        <select
                            style={{ marginLeft: '30px', height: '30px' }}
                            type="select"
                            name="Tamaño"
                            id="Tamaño"
                            placeholder="Tamaño"
                            onChange={(e) => setSize(e.target.value)}
                        >
                            <option value="0">Pequeño</option>
                            <option value="1">Mediano</option>
                            <option value="2">Grande</option>
                            <option value="3">Extra Grande</option>
                        </select>
                        <select
                            style={{ marginLeft: '30px', height: '30px' }}
                            type="select"
                            name="Tipografia"
                            id="TIPOGRAFIA"
                            placeholder="Tipografia"
                            onChange={(e) => setFont(e.target.value)}
                        >
                            <option value="0">LETRA CARTA DancingScript</option>
                            <option value="1">LETRA MOLDE Ubuntu</option>
                            <option value="2">LETRA MOLDE PlayfairDisplay</option>
                            <option value="3">LETRA MOLDE Quicksand</option>
                            <option value="4">LETRA MOLDE Bebas_Neue</option>

                            <option value="5">LETRA CARTA Whisper</option>
                            <option value="6">LETRA CARTA Pacifico</option>
                            <option value="7">LETRA CARTA BeauRivage</option>
                            <option value="8">LETRA CARTA CedarvilleCursive</option>
                            {/* <option value="2">Grande</option>
                            <option value="3">Extra Grande</option> */}
                        </select>

                    </div>
                    {/* COLOR */}
                    <label style={{ textAlign: 'center' }} htmlFor="colorPicker">Selecciona un color RGB:</label>
                    <div style={{ textAlign: 'center' }}>
                        <input
                            style={{ marginTop: '20px', height: '50px' }}
                            type="color"
                            id="colorPicker"
                            value={selectedColor}
                            onChange={handleColorChange}
                        />
                    </div>
                    {/*   <Draggable onDrag={handleDrag} {...dragHandlers}>
                        <div className="box" style={{ width: '450px', height: '50px' }}>
                            <p>{deltaPosition.x + ',' + deltaPosition.y}</p>
                            <p style={{ color: selectedColor }}>
                                {text && text}
                            </p>
                        </div>
                    </Draggable> */}
                </>
            }

            {

                <div style={{ width: '450px', height: '250px', placeSelf: 'center' }}>
                    {
                        imageFlag ?
                            <>
                                {
                                    image &&
                                    <img style={{ width: '450px', height: '250px', placeSelf: 'center' }} src={URL.createObjectURL(image)} alt="lsa" />
                                }
                            </>
                            :
                            <div style={{ background: selectedColorImage, height: '250px', width: '450px' }}>

                            </div>
                    }
                    <p style={{
                        color: selectedColor, position: 'absolute',
                        width: '450px',
                        /* height: '100px', */
                        fontSize: size === '0' ? '25px' : size === '1' ? '22px' : size === '2' ? '24px' : '40px',
                        fontFamily: font === '0' ? 'DancingScript-Medium' : font === '1' ? 'Ubuntu-Medium' :
                            font === '3' ? 'Quicksand-Medium' : font === '4' ? 'BebasNeue-Regular' :
                                font === '5' ? 'Whisper-Regular' : font === '6' ? 'Pacifico-Regular' : font === 7 ? 'BeauRivage-Regular' : 'CedarvilleCursive-Regular',
                        textAlign: coordenadas === '1' || coordenadas === '2'? 'left' : coordenadas === '7' || coordenadas === '8' ?  "right" : "center",
                        //marginLeft: Math.min(text.length * 2.25 , 14)  + '%',
                        marginTop: coordenadas === '0' || coordenadas === '1' || coordenadas === '7'  ? '-160px' : coordenadas === '5' ? '-240px' : coordenadas === '4' || coordenadas === '2' || coordenadas === '8'? '-110px'  : coordenadas == '2'? '-35px' : '-35px'
                    }} >{text && text}</p>

                    <p style={{
                        color: selectedColor, position: 'absolute',
                        width: '450px',
                        /* height: '100px', */
                        fontSize: size === '0' ? '25px' : size === '1' ? '22px' : size === '2' ? '24px' : '40px',
                        fontFamily: font === '0' ? 'DancingScript-Medium' : font === '1' ? 'Ubuntu-Medium' :
                            font === '3' ? 'Quicksand-Medium' : font === '4' ? 'BebasNeue-Regular' :
                                font === '5' ? 'Whisper-Regular' : font === '6' ? 'Pacifico-Regular' : font === 7 ? 'BeauRivage-Regular' : 'CedarvilleCursive-Regular',

                        textAlign: 'center',
                        //marginLeft: Math.min(text.length * 2.25 , 14)  + '%',
                        marginTop:'-80px'
                    }} >{text2 && text2}</p>

                    <p style={{
                        color: selectedColor, position: 'absolute',
                        width: '450px',
                        /* height: '100px', */
                        fontSize: size === '0' ? '25px' : size === '1' ? '22px' : size === '2' ? '24px' : '40px',
                        fontFamily: font === '0' ? 'DancingScript-Medium' : font === '1' ? 'Ubuntu-Medium' :
                            font === '3' ? 'Quicksand-Medium' : font === '4' ? 'BebasNeue-Regular' :
                                font === '5' ? 'Whisper-Regular' : font === '6' ? 'Pacifico-Regular' : font === 7 ? 'BeauRivage-Regular' : 'CedarvilleCursive-Regular',
                        textAlign: 'center',
                        //marginLeft: Math.min(text.length * 2.25 , 14)  + '%',
                        marginTop: '-45px' 
                    }} >{text3 && text3}</p>
                </div>

            }
            <div style={{ textAlignLast: 'center', marginTop: '30px' }}>
                <button style={{ width: '40%', }} onClick={createPDF}>Imprimir Fondo</button>
            </div>
            <div style={{ textAlignLast: 'center', marginTop: '30px', marginBottom: '20px' }}>
                <button
                    style={{ width: '40%', }}
                    onClick={() => {
                        setSelectedColor('#ff0000')
                        setImage(null)
                        setText('')
                        setTextFlag(false)
                        window.location.reload()
                    }}>Refrescar Pagina</button>
            </div>


        </div>
    );
};

export default App;